import { useEffect, useState } from 'react';

/**
 * Hook to determinate if the component is mounted
 * Also used to fix hydration error:
 * https://nextjs.org/docs/messages/react-hydration-error#possible-ways-to-fix-it
 */

const useMounted = (): boolean => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(true);
  }, []);

  return ready;
};

/**
 * Export default hook
 */

export default useMounted;
