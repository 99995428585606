import { ImageUrlBuilderOptions } from '@sanity/image-url/lib/types/types';
import { SanityImageObject } from 'src/components/Image/SanityImage';
import { BP, sortedTwBpsObj } from 'src/helpers/tailwindBreakpointsHelpers';
import { GenericImage } from 'src/typings/runtime-typecheck/sanityGenericImage';
import { SanityImage as SanityGenericImage } from 'src/typings/sanityTypes';
import { sanityImageFor } from 'src/utils/sanityClient';

const SUPPORTED_DPR: number[] = [1, 2, 3];
export const MAX_DEFAULT_IMAGE_WIDTH = 1920; // for 1440 and up

// removes object properties that doesn't have values or are undefined/null
const removeEmpty = (obj) =>
  Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== null && v !== undefined && v !== ''));

// returns sanity url for an image
export const buildSanityImageSrc = (
  imgObject: SanityImageObject,
  imgOptions: ImageUrlBuilderOptions
): React.ImgHTMLAttributes<HTMLImageElement>['src'] =>
  sanityImageFor(imgObject).withOptions(removeEmpty(imgOptions)).auto('format').url() || undefined;

// returns image srcSet that supports multiple dpi
export const buildSanityImageSrcSet = (
  imgObject: SanityImageObject,
  imgOptions: ImageUrlBuilderOptions,
  dpr: number[] = SUPPORTED_DPR
): React.ImgHTMLAttributes<HTMLImageElement>['srcSet'] =>
  dpr
    .map((ratio) => {
      const imgSrc = buildSanityImageSrc(imgObject, {
        ...imgOptions,
        dpr: ratio,
        quality: imgOptions.quality || Math.floor(85 / ratio),
        ...(ratio > 1 && { sharpen: 20 }),
      });
      return imgSrc ? `${imgSrc} ${ratio}x` : '';
    })
    .join();

// returns a style object you can use to set backgroundImage that supports dpi
export const buildSanityBkgImageSet = (
  imgObject: SanityImageObject,
  imgOptions: ImageUrlBuilderOptions,
  styleProps?: string,
  dpr: number[] = SUPPORTED_DPR
): React.CSSProperties => {
  const urlString = dpr
    .map((ratio) => {
      const imgSrc = buildSanityImageSrc(imgObject, {
        ...imgOptions,
        dpr: ratio,
        quality: imgOptions.quality || Math.floor(85 / ratio),
        ...(ratio > 1 && { sharpen: 20 }),
      });
      return imgSrc ? `url(${imgSrc}) ${ratio}x` : '';
    })
    .join();
  return {
    // image-set() with the webkit prefix has the most support as of now
    // if you add the fallback, as MDN recommends, it loads 2 images :( so don't
    backgroundImage: `${styleProps ? `${styleProps}, ` : ''} -webkit-image-set(${urlString})`,
  };
};

const padCustomSizeSet = (twBpKeys: BP[], imgSizeSet: BkgImageSizeSet): SizeSetBackgroundImageOptionsObj[] => {
  let sizeSetIndex = 0;
  const padCustomSizeSet: SizeSetBackgroundImageOptionsObj[] = [];
  for (const bp of twBpKeys) {
    const useNextBpImgOptions = imgSizeSet.length - 1 > sizeSetIndex && imgSizeSet[sizeSetIndex + 1].bp === bp;
    if (useNextBpImgOptions) {
      sizeSetIndex += 1;
    }
    padCustomSizeSet.push({ bp, sanityImgOptions: imgSizeSet[sizeSetIndex].sanityImgOptions });
  }
  return padCustomSizeSet;
};

const getImgOptionsForBp = (twBpKeys: BP[], currentBp: BP, imgSizeSet?: BkgImageSizeSet): ImageUrlBuilderOptions => {
  // lets see if provided breakpoint is in the tailwind config
  const validCurrentBpIndex = twBpKeys.findIndex((bp) => bp === currentBp);

  if (validCurrentBpIndex > -1) {
    // If we have a custom size set
    if (imgSizeSet) {
      const currentBpImgSet = imgSizeSet.find(({ bp }) => bp === currentBp);
      if (!currentBpImgSet) {
        // no matching bp, so lets find the closest match
        const currentPaddedBpImgSet = padCustomSizeSet(twBpKeys, imgSizeSet).find(({ bp }) => bp === currentBp);
        if (!currentPaddedBpImgSet || !currentPaddedBpImgSet.sanityImgOptions) {
          console.warn(
            "Something went wrong with your custom background image. This means you'll load a very big image!"
          );
          return {};
        }
        return currentPaddedBpImgSet.sanityImgOptions;
      } else if (!currentBpImgSet.sanityImgOptions) {
        console.warn("No image options found for your background image. This means you'll load a very big image!");
        return {};
      } else {
        return currentBpImgSet.sanityImgOptions;
      }

      // else we set it to default breakpoints from tw
    } else if (twBpKeys.length === validCurrentBpIndex + 1) {
      // for the largest bp we use the max width
      return { width: MAX_DEFAULT_IMAGE_WIDTH };
    } else {
      // to avoid stretching we set the width the the biggest possible size for the breakpoint, aka the next breakpoint
      return { width: sortedTwBpsObj[twBpKeys[validCurrentBpIndex + 1]] };
    }
  } else {
    throw new Error('Supplied breakpoint for backgroundImg is not valid');
  }
};

type SizeSetBackgroundImageOptionsObj = {
  sanityImgOptions?: ImageUrlBuilderOptions;
  bp: BP;
};
export type BkgImageSizeSet = [
  SizeSetBackgroundImageOptionsObj,
  SizeSetBackgroundImageOptionsObj,
  ...SizeSetBackgroundImageOptionsObj[]
];

export const getResponsiveBackgroundImage = (
  imgObject: SanityImageObject,
  currentBp: BP | null,
  imgSizeSet?: BkgImageSizeSet,
  styleProps?: string
): React.CSSProperties => {
  const imgOptions = getImgOptionsForBp(Object.keys(sortedTwBpsObj) as BP[], currentBp || 'xs', imgSizeSet);

  return buildSanityBkgImageSet(imgObject, imgOptions, styleProps);
};

export const sanityImgObjectFromGenericImage = (sanityImage: SanityGenericImage | GenericImage): SanityImageObject => {
  return { alt: sanityImage.alt || '', ...sanityImage.image };
};
