import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ClientOnly from 'src/components/ClientOnly';
import { loadState, saveState, StorageKeys } from 'src/helpers/localStorage';
import { BP } from 'src/helpers/tailwindBreakpointsHelpers';
import { useActiveBreakpoint } from 'src/hooks/useActiveBreakpoint';
import { State } from 'src/redux/reducers';
import { isMobile } from 'src/utils/isMobile';
import { isTablet } from 'src/utils/isTablet';

import { Typography } from '@remarkable/ark-web';
import Modal from 'src/components/Modal';
import SFSignupForm from '../shared/SFSignupForm';

interface Props {
  onClose: () => void;
}

const MarketingOptInModal: React.FC<Props> = ({ onClose }) => {
  const marketingOpt = useSelector((state: State) => state.staticQuery.marketingOptIn);

  if (!marketingOpt) {
    return null;
  }

  return (
    <>
      <Modal show handleClose={onClose}>
        <div
          className="h-[360px] w-[856px] bg-cover bg-center rounded-t-[9px]"
          style={{ backgroundImage: `url(${marketingOpt.image.url})` }}
        />
        <div className="text-center pt-[80px] px-[20px] max-w-[600px] mx-auto mb-64">
          <Typography as="h2" variant="heading-lg" className="text-[40px] mb-32">
            {marketingOpt?.heading}
          </Typography>
          <Typography as="p" variant="body-lg-book" className="mb-32">
            {marketingOpt?.byline}
          </Typography>
          <SFSignupForm />
        </div>
      </Modal>
    </>
  );
};

const MartketingOptInExitIntent: React.FC<{
  open?: boolean;
}> = ({ open }) => {
  const [isOpen, setIsOpen] = useState(false);
  const currentBp: BP | null = useActiveBreakpoint();
  const isMobileOrTablet = isMobile(currentBp) || isTablet(currentBp);
  const haveDismissed = loadState(StorageKeys.MARKETING_OPT_IN_DISMISSED);

  const onClose = useCallback(() => {
    setIsOpen(false);
    saveState(StorageKeys.MARKETING_OPT_IN_DISMISSED, 'true');
  }, []);

  useEffect(() => {
    let timer;

    const exitIntent = () => {
      const mouseEvent = (e: MouseEvent) => {
        // Activate if mouse is not inside the browser window and
        // the cursor is on top of the screen
        if (!e.relatedTarget && e.clientY < 10) {
          const haveSignedUp = loadState(StorageKeys.EMAIL_PROMT_SIGNED_UP);

          if (!haveSignedUp) {
            setIsOpen(true);
          }
        }
      };

      // wait  10 seconds before we kick the event listener off
      timer = setTimeout(() => {
        document.addEventListener('mouseout', mouseEvent);
      }, 10000);
    };

    exitIntent();

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Close modal if Escape is pressed
    const escFunction = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsOpen(false);
      }
    };

    document.addEventListener('keydown', escFunction);

    return () => document.removeEventListener('keydown', escFunction);
  }, []);

  if (isMobileOrTablet || haveDismissed) {
    return null;
  }

  const isOpened = open || isOpen;

  if (!isOpened) {
    return null;
  }

  return (
    <ClientOnly>
      <MarketingOptInModal onClose={onClose} />
    </ClientOnly>
  );
};

export default MartketingOptInExitIntent;
