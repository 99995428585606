export type Link = {
  text: string;
  href: string;
};

export const links: Link[] = [
  { text: 'Our story', href: '/about' },
  { text: 'Transparency Act', href: '/transparency-act' },
  {
    text: 'FAQ & Support',
    href: 'https://support.remarkable.com',
  },
  { text: 'Blog', href: '/blog' },
  { text: 'Careers', href: 'https://careers.remarkable.com' },
  { text: 'Contact', href: '/contact' },
];

export const legalLinks: Link[] = [
  {
    text: 'Legal overview',
    href: 'https://support.remarkable.com/s/topic/0TO7Q000000sxrVWAQ/legal',
  },
  {
    text: 'Terms & conditions',
    href: 'https://support.remarkable.com/s/topic/0TO7Q000000sz6TWAQ/hardware-tcs',
  },
  {
    text: 'Privacy policy',
    href: 'https://support.remarkable.com/s/article/reMarkable-s-privacy-policy',
  },
  {
    text: 'Terms & conditions Cloud Subscription',
    href: 'https://support.remarkable.com/s/topic/0TO7Q000000sz6UWAQ/cloud-tcs',
  },
  {
    text: 'End User License Agreement',
    href: 'https://support.remarkable.com/s/topic/0TO7Q000000sz6VWAQ/end-user-tcs',
  },
  {
    text: 'Cookie policy',
    href: 'https://support.remarkable.com/s/topic/0TO7Q000000sz6XWAQ/cookie-policy',
  },
];
