import React, { createContext, useContext, useEffect, useState } from 'react';
import { FEATURE_CONSENT_2023_ENABLED } from 'src/components/CookieConsent/featureFlag';
import { loadState, StorageKeys } from 'src/helpers/localStorage';

export const CookieBarContext = createContext<
  { cookieBarDismissed: boolean; toggleCookieBarDismissed: (boolean) => void } | undefined
>(undefined);

export const CookieBarContextProvider: React.FC = ({ children }) => {
  const [cookieBarDismissed, toggleCookieBarDismissed] = useState(!!loadState(StorageKeys.COOKIE_BAR_DISMISSED));
  const [isMounted, setIsMounted] = useState(false);

  // Ensure that UI is updated after initial load if SSG is used.
  useEffect(() => {
    setIsMounted(true);
  }, [isMounted]);

  return (
    <CookieBarContext.Provider
      value={{
        cookieBarDismissed: FEATURE_CONSENT_2023_ENABLED || (isMounted && cookieBarDismissed),
        toggleCookieBarDismissed,
      }}
    >
      {children}
    </CookieBarContext.Provider>
  );
};

export const useCookieBarContext = (): { cookieBarDismissed: boolean; toggleCookieBarDismissed: (boolean) => void } => {
  const context = useContext(CookieBarContext);

  if (context === undefined) {
    throw new Error('useCookieBarContext was used outside of its Provider');
  }

  return context;
};
