import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ClientOnly from 'src/components/ClientOnly';
import { loadState, saveState, StorageKeys } from 'src/helpers/localStorage';
import useOnScreen from 'src/hooks/useOnScreen';
import { State } from 'src/redux/reducers';

import Desktop from './Desktop/MarketingOptInOnScrollDesktop';
import Mobile from './Mobile/MarketingOptInOnScrollMobile';

interface Props {
  open?: boolean;
  isCI?: boolean;
}

const MarketingOptInOnScroll: React.FC<Props> = ({ open, children }) => {
  const [haveBeenTriggered, setHaveBeenTriggerd] = useState(false);
  const [ref, onScreen] = useOnScreen();
  const marketingOpt = useSelector((state: State) => state.staticQuery.marketingOptIn);

  const onClose = useCallback(() => {
    setHaveBeenTriggerd(false);
  }, []);

  useEffect(() => {
    const haveSignedUp = loadState(StorageKeys.EMAIL_PROMT_SIGNED_UP);
    const isDismissed = loadState(StorageKeys.MARKETING_OPT_IN_DISMISSED);

    if (open || (onScreen && !isDismissed && !haveSignedUp)) {
      saveState(StorageKeys.MARKETING_OPT_IN_DISMISSED, 'true');
      setHaveBeenTriggerd(true);
    }
  }, [open, onScreen]);

  return (
    <>
      {(open || haveBeenTriggered) && (
        <ClientOnly>
          <>
            <Mobile onClose={onClose} marketingOptIn={marketingOpt} />
            <Desktop onClose={onClose} marketingOptIn={marketingOpt} />
          </>
        </ClientOnly>
      )}

      <span ref={ref}>{children}</span>
    </>
  );
};

export default MarketingOptInOnScroll;
