import cx from 'classnames';
import Link from 'next/link';
import React from 'react';

import { Typography } from '@remarkable/ark-web';
import { Link as LinkType } from './links';

type Props = {
  title: string;
  links: LinkType[];
  trackClick: (label: string) => void;
};

const LinkList: React.FunctionComponent<Props> = ({ title, links, trackClick }) => (
  <>
    <Typography className="m-0 md:mb-8 text-grayscale-white" as="h3" variant="body-md-bold">
      {title}
    </Typography>
    <ul className={cx('list-none p-0 leading-[1.5] md:leading-[2]')}>
      {links.map(({ text, href }) => (
        <li key={href}>
          {href.startsWith('http') ? (
            <a className="no-underline" href={href} target="_blank" rel="noreferrer" onClick={() => trackClick(text)}>
              {text}
            </a>
          ) : (
            <Link href={href} className="no-underline" onClick={() => trackClick(text)}>
              {text}
            </Link>
          )}
        </li>
      ))}
    </ul>
  </>
);

export default LinkList;
