import React from 'react';
import { MarketingData } from 'src/queries/groq/commonProviderData';

import { Typography } from '@remarkable/ark-web';
import SFSignupForm from '../../shared/SFSignupForm';

interface Props {
  marketingOptIn: MarketingData;
  onClose: () => void;
}

const MarketingOptInOnScrollDesktop: React.FC<Props> = ({ onClose, marketingOptIn }) => {
  return (
    <section className="hidden lg:block bg-[#152934] text-[#efefef] fixed right-[20px] bottom-[20px] max-w-[640px] flex justify-between flex-row-reverse rounded-[8px] shadow-[1px_3px_20px_rgba(0,0,0,0.5)] animate-[enter_400ms] z-10">
      <button
        className="absolute top-[10px] right-[10px] w-[50px] h-[50px] text-[24px]"
        onClick={onClose}
        children="✕"
        data-cy="marketing-opt-in-close-button"
      />
      <div
        className="bg-cover bg-center h-[320px] rounded-r-[8px] flex-1"
        style={{ backgroundImage: `url(${marketingOptIn.image.url})` }}
      />
      <div className="text-center mx-auto relative flex flex-col justify-center flex-1 py-20 px-40">
        <Typography as="h2" variant="heading-sm" className="mb-16" data-theme="on-dark">
          {marketingOptIn.heading}
        </Typography>
        <Typography as="p" variant="body-md-book" className="mb-32" data-theme="on-dark">
          {marketingOptIn.byline}
        </Typography>
        <SFSignupForm small />
      </div>
    </section>
  );
};

export default MarketingOptInOnScrollDesktop;
