import cx from 'classnames';
import { FC, useEffect } from 'react';
import CookieBar from 'src/components/CookieBar/CookieBar';
import { CookieBarContextProvider } from 'src/contexts/CookieBarContext';
import { updateUTMCampaign } from 'src/helpers/campaignHelpers';

import { FEATURE_CONSENT_2023_ENABLED } from 'src/components/CookieConsent/featureFlag';
import Footer from './Footer';

interface Props {
  children: React.ReactNode;
  hideFooter?: boolean;
  hideMarketingOptIn?: boolean;
  className?: string;
}

const Layout: FC<Props> = ({ children, hideFooter, hideMarketingOptIn, className }) => {
  useEffect(() => {
    updateUTMCampaign();
  }, []);

  return (
    <CookieBarContextProvider>
      {/* The two wrapper divs makes the footer sticky */}
      <div className={cx(className, 'flex flex-col min-h-full')}>
        <div className={cx('flex grow flex-col')}>
          <div id="rm-modal-portal" />
          {children}
        </div>
        {!FEATURE_CONSENT_2023_ENABLED && <CookieBar />}
        {!hideFooter ? <Footer hideMarketingOptIn={hideMarketingOptIn} /> : null}
      </div>
    </CookieBarContextProvider>
  );
};

export default Layout;
