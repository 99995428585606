import { Button } from '@remarkable/ark-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { CookieBarContext } from 'src/contexts/CookieBarContext';
import { loadState, saveState, StorageKeys } from 'src/helpers/localStorage';
import { getCountryInfoByValue } from 'src/helpers/storeHelpers';
import { State } from 'src/redux/reducers';

type CookieBarProps = ReturnType<typeof mapStateToProps>;

interface CookieBarState {
  isDismissed: boolean;
}
class CookieBar extends React.PureComponent<CookieBarProps, CookieBarState> {
  static contextType = CookieBarContext;

  constructor(props: CookieBarProps) {
    super(props);
    this.state = {
      isDismissed: true,
    };

    this.onDismissButtonClick = this.onDismissButtonClick.bind(this);
  }

  componentDidMount() {
    const isDismissed = loadState(StorageKeys.COOKIE_BAR_DISMISSED);
    this.context.toggleCookieBarDismissed(!!isDismissed);
    this.setState({
      isDismissed,
    });
  }

  componentDidUpdate(prevProps: CookieBarProps) {
    if (!this.state.isDismissed && prevProps.country !== this.props.country) {
      const countryData = this.props.countryData;
      const currentCountry = getCountryInfoByValue(countryData, this.props.country);
      if (currentCountry && currentCountry.continent !== 'Europe') {
        this.setState({
          isDismissed: true,
        });
        saveState(StorageKeys.COOKIE_BAR_DISMISSED, 'true');
        this.context.toggleCookieBarDismissed(true);
      }
    }
  }

  onDismissButtonClick(event: React.FormEvent) {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      isDismissed: true,
    });
    saveState(StorageKeys.COOKIE_BAR_DISMISSED, 'true');
    this.context.toggleCookieBarDismissed(true);
  }

  render() {
    const { isDismissed } = this.state;
    if (isDismissed) {
      return null;
    }

    return (
      <div
        className="fixed bottom-0 z-[1000] flex items-center justify-center w-full px-16 bg-white h-56 md:h-[42px]"
        data-cy="cookiebar"
      >
        <p className="pr-12 my-12 font-book text-12 lg:text-14 leading-[18px]">
          We use cookies to ensure a great user experience on our website. See our{' '}
          <a
            className="underline"
            href="https://support.remarkable.com/s/article/reMarkable-s-cookie-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            cookie policy
          </a>
        </p>
        <Button
          size="small"
          className="ml-0 md:ml-20"
          id="cookieBar-button"
          data-cy="cookiebar-dismiss"
          onClick={this.onDismissButtonClick}
        >
          OK
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state: State) => ({
  country: state.countryDetector.country,
  countryData: state.staticQuery.countryData,
});

export default connect(mapStateToProps)(CookieBar);
