import { Envelope } from 'phosphor-react';
import React from 'react';
import { useSelector } from 'react-redux';
import ClientOnly from 'src/components/ClientOnly';
import SanityResponsiveImage from 'src/components/Image/SanityResponsiveImage';
import { State } from 'src/redux/reducers';

import { Typography } from '@remarkable/ark-web';
import SFSignupForm from '../shared/SFSignupForm';

export enum SectionType {
  MainPage,
  Blog,
}

interface Props {
  type: SectionType;
}

const MainPageWrapper: React.FC = (props) => {
  return (
    <section>
      <div className="max-w-[1360px] mx-auto">
        <div className="px-24 py-40 md:py-64 xl:px-0">{props.children}</div>
      </div>
    </section>
  );
};

const BlogWrapper: React.FC = (props) => {
  return (
    <div className="max-w-[1360px] my-12 md:my-8 lg:my-12 xl:my-16 mx-24 md:mx-56 lg:mx-[72px] xl:mx-auto grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-24 md:gap-16 lg:gap-24 xl:gap-32 [&>section:first-child]:col-span-full">
      {props.children}{' '}
    </div>
  );
};

const MarketingOptInSection: React.FC<Props> = ({ type }) => {
  const marketingOpt = useSelector((state: State) => state.staticQuery.marketingOptIn);

  const Wrapper = type === SectionType.Blog ? BlogWrapper : MainPageWrapper;

  return (
    <ClientOnly>
      <Wrapper>
        <section
          className={`bg-brand-blue-800 text-light md:flex md:justify-between md:flex-row-reverse ${
            type === SectionType.Blog ? 'rounded-[4px]' : ''
          } `}
        >
          <SanityResponsiveImage
            pictureClassName={type === SectionType.Blog ? '' : 'h-[450px] md:h-unset md:basis-1/2 lg:basis-2/3'}
            imgClassName={`object-cover w-full h-full bg-cover ${
              type === SectionType.Blog ? 'rounded-tl-[4px] rounded-tr-[4px] md:rounded-r-[4px] md:rounded-tl-none' : ''
            } `}
            imgDefault={{
              sanityImgObject: { asset: { url: marketingOpt.image.url, alt: marketingOpt.image.alt } },
            }}
            alt={marketingOpt.image.alt ?? ''}
          />
          <div
            className={`md:flex md:flex-col md:justify-center md:py-20 md:px-48 md:basis-1/2 lg:basis-1/3 text-center px-20 pt-48 pb-40 `}
          >
            <div>
              <Envelope size={32} className="hidden md:inline bg-brand-blue-500 box-content rounded-full p-8 mb-24" />
              <Typography as="h2" variant="heading-sm" className="mb-16 text-grayscale-gray-50">
                {marketingOpt.heading}
              </Typography>
              <Typography as="p" variant="body-lg-book" className="mb-32 text-grayscale-gray-50">
                {marketingOpt.byline}
              </Typography>
              <SFSignupForm small />
            </div>
          </div>
        </section>
      </Wrapper>
    </ClientOnly>
  );
};

export default MarketingOptInSection;
