import cx from 'classnames';
import { CaretCircleUp, XCircle } from 'phosphor-react';
import React, { useState } from 'react';
import { MarketingData } from 'src/queries/groq/commonProviderData';

import { Typography } from '@remarkable/ark-web';
import SFSignupForm from '../../shared/SFSignupForm';

interface Props {
  marketingOptIn: MarketingData;
  onClose: () => void;
}

const MarketingOptInOnScrollMobile: React.FC<Props> = ({ marketingOptIn }) => {
  const [minimized, setMinimized] = useState(false);

  const icon = minimized ? <CaretCircleUp size={30} /> : <XCircle size={30} />;

  return (
    <div
      className={cx(
        'flex lg:hidden flex-row fixed bottom-0 right-0 left-0 text-center shadow-[1px_3px_20px_rgba(0,0,0,0.15)] transition-[height_160ms] z-10 bg-[#fff]',
        {
          'py-[48px] px-24 items-end max-h-[300px] sm:items-center': !minimized,
          'items-center h-[48px] py-0': minimized,
        }
      )}
    >
      <button
        className={cx('absolute p-0 right-16', {
          'top-16': !minimized,
          'top-[-3px] mt-[10px]': minimized,
        })}
        onClick={() => setMinimized(!minimized)}
        children={icon}
      />
      <div className="w-full">
        {minimized ? (
          <Typography as="h2" variant="heading-sm" className="text-18" onClick={() => setMinimized(false)}>
            {marketingOptIn.heading}
          </Typography>
        ) : (
          <>
            <Typography as="h2" variant="heading-sm" className="mb-16 text-24">
              {marketingOptIn.heading}
            </Typography>
            <Typography as="p" variant="body-md-book" className="mb-32">
              {marketingOptIn.byline}
            </Typography>
            <SFSignupForm small />
          </>
        )}
      </div>
    </div>
  );
};

export default MarketingOptInOnScrollMobile;
