import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCountryInfoByValue } from 'src/helpers/storeHelpers';
import { State } from 'src/redux/reducers';
import { SanityCountry, SanityCurrency } from 'src/typings/sanityTypes';

export interface UseCountryData {
  country: string;
  region: string | undefined;
  currency: string;
  currencyDetails: SanityCurrency;
  countryHasBeenSet: boolean;
  sanityCountry: SanityCountry;
  countryData: SanityCountry[];
}

/*
 * Requires getCommonProviderData() in getStaticProps to use.
 */
export const useCountryData = (): UseCountryData => {
  const countryHasBeenSet = useSelector((state: State) => state.countryDetector.hasBeenSet);
  const country = useSelector((state: State) => state.countryDetector.country);
  const region = useSelector((state: State) => state.countryDetector.region);
  const countryData = useSelector((state: State) => state.staticQuery.countryData);
  const sanityCountry = useMemo(() => getCountryInfoByValue(countryData, country), [country, countryData]);

  return {
    country: sanityCountry.value,
    currency: sanityCountry.currency.value,
    currencyDetails: sanityCountry.currency,
    region,
    countryHasBeenSet,
    sanityCountry,
    countryData,
  };
};
