import { useSelector } from 'react-redux';
import ClientOnly from 'src/components/ClientOnly';
import { State } from 'src/redux/reducers';

import { Typography } from '@remarkable/ark-web';
import SFSignupForm from '../shared/SFSignupForm';

const MartketingOptInFooter = () => {
  const marketingOpt = useSelector((state: State) => state.staticQuery.marketingOptIn);

  return (
    <ClientOnly>
      <section className="relative flex justify-center bg-grayscale-gray-800 p-24 pt-64 md:pt-[80px]">
        <div className="text-center max-w-[500px]">
          <Typography as="h2" variant="heading-sm" className="mb-16 text-grayscale-gray-50">
            {marketingOpt.heading}
          </Typography>
          <Typography as="p" variant="body-lg-book" className="mb-32 text-grayscale-gray-50">
            {marketingOpt.byline}
          </Typography>
          <SFSignupForm />
        </div>
      </section>
    </ClientOnly>
  );
};

export default MartketingOptInFooter;
