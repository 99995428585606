import { ImageUrlBuilderOptions, SanityImageSource } from '@sanity/image-url/lib/types/types';
import { FC, ImgHTMLAttributes } from 'react';
import { unreachableCode } from 'src/utils/unreachableCode';

import { ImageElementStyles } from '../Image';
import { buildSanityImageSrc, buildSanityImageSrcSet, MAX_DEFAULT_IMAGE_WIDTH } from './sanityImageHelpers';

export type SanityImageObject = Exclude<SanityImageSource, string> & {
  alt?: string;
  bgColor?: string;
  isCropped?: boolean;
  selector?: string;
};

export type FitModes = 'contain' | 'cover';

const computeClassName = (fitMode?: FitModes, isCropped?: boolean | null): string => {
  if (fitMode !== undefined) {
    if (isCropped) {
      // Defined as a cropped image, but fitMode is also defined. Ignoring isCropped, this could be an issue.`
    }
    if (fitMode === 'cover') {
      return 'object-cover w-full h-full';
    } else if (fitMode === 'contain') {
      return 'object-contain w-full h-full';
    } else {
      return unreachableCode(`Unhandled value: ${fitMode}`, fitMode);
    }
  } else if (isCropped !== undefined && isCropped !== null) {
    // Sometimes Sanity API sets isCropped === null when the property
    // isn't defined in CMS. It is important to treat it same as undefined
    return isCropped ? 'object-cover' : 'object-contain';
  }
  return '';
};

export type SanityImageProps = {
  sanityImgObject: SanityImageObject;
  sanityImgOptions?: ImageUrlBuilderOptions;
  fitMode?: FitModes;
};
/**
 * This component utilizes the sanity image API to optimize an image, where
 * we can define focal point and crop in Sanity Studio and this component will honor
 * that. You have to use SanityImageSource data, not just the url, to get the crop/hotspot data from sanity.
 * If you only set height OR weight, it will give you the full image or the cropped image if you have set crop in sanity.
 * To crop according to focalpoint, you have to set height AND width in the image options here.
 * Use PNGs to be able to set background color.
 * It will always serve wepP format when possible as auto('format') is set.
 * There's lots of other sanity options to play around with. Stay away from them if you wanna stay sane.
 *
 */
const SanityImage: FC<SanityImageProps & ImageElementStyles & ImgHTMLAttributes<HTMLImageElement>> = ({
  sanityImgObject,
  sanityImgOptions = {},
  loading = 'lazy',
  imgStyle = {},
  imgClassName = '',
  fitMode, // overrides isCropped if both are set
  ...rest
  // TODO: add default size and quality?
}): JSX.Element => {
  const { width, height, ...restOptions } = sanityImgOptions;
  const restrainedWidth = !width && !height ? MAX_DEFAULT_IMAGE_WIDTH : width;
  const restrainedImgOptions = { width: restrainedWidth, height, ...restOptions };
  const imgSrc = buildSanityImageSrc(sanityImgObject, restrainedImgOptions);

  if (!imgSrc) {
    console.warn('Could not find any imgSrc for SanityImage', sanityImgObject);
    return <img className={imgClassName} />;
  }

  const { alt, bgColor, isCropped } = sanityImgObject;

  return (
    <img
      data-cy="product-image"
      src={imgSrc}
      srcSet={buildSanityImageSrcSet(sanityImgObject, restrainedImgOptions)}
      alt={alt}
      className={`${computeClassName(fitMode, isCropped)} ${imgClassName}`}
      loading={loading}
      style={bgColor ? { backgroundColor: bgColor, ...imgStyle } : imgStyle}
      {...rest}
    />
  );
};

export default SanityImage;
