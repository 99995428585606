import { CSSProperties, FC, ImgHTMLAttributes } from 'react';

export type PictureElementStyling = {
  pictureStyle?: CSSProperties;
  pictureClassName?: string;
};
export type ResponsiveImageSrcSet = { bp: number; srcSet: ImgHTMLAttributes<HTMLImageElement>['src'] }[];
type Props = {
  imgSrcSet: ResponsiveImageSrcSet;
  children: JSX.Element;
} & PictureElementStyling;

/**
 * Sets up <picture> tag with sources (with srcSet) that describes a list of sizes for different sizes.
 * The <picture> container needs a single <img> passed in children prop to be default fallback image if none of the
 * <sources> fit browser/size conditions.
 * @param imgSrcSet
 * @param pictureStyle
 * @param pictureClassName
 * @param children
 * @constructor
 */
const ResponsiveImagePictureContainer: FC<Props> = ({
  imgSrcSet,
  pictureStyle = {},
  pictureClassName = '',
  children,
}): JSX.Element => (
  <picture className={pictureClassName} style={pictureStyle}>
    {imgSrcSet &&
      imgSrcSet
        .sort(({ bp: x }, { bp: y }) => y - x) // sorts decending, the source tags order matter!!
        .map(({ bp, srcSet }, index) => <source key={index} media={`(min-width: ${bp}px)`} srcSet={srcSet} />)}
    {children}
  </picture>
);

export default ResponsiveImagePictureContainer;
