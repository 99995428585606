import { loadState, saveState, StorageKeys } from './localStorage';

export interface UTMParams {
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_content: string;
  utm_term: string;
}

export interface UTMCampaign {
  timestamp: number;
  campaign: UTMParams;
}

export const getUTMParams = (): UTMParams => {
  const pageUri = window.location.href;
  const url = new URL(pageUri).searchParams;

  return {
    utm_source: url.get('utm_source') || '',
    utm_medium: url.get('utm_medium') || '',
    utm_campaign: url.get('utm_campaign') || '',
    utm_content: url.get('utm_content') || '',
    utm_term: url.get('utm_term') || '',
  };
};

export const updateUTMCampaign = () => {
  const utmCampaign: UTMCampaign[] = loadState(StorageKeys.UTM_CAMPAIGN);
  const utmParams = getUTMParams();

  // HubSpots date format
  const updateDate = new Date();
  updateDate.setUTCHours(0, 0, 0, 0);
  const timestamp = updateDate.getTime();

  /* [12.07.2021: Extra if check needed to clear erroneous localStorage where UTM_CAMPAIGN is stored as string */
  if (!Array.isArray(utmCampaign)) {
    saveState(StorageKeys.UTM_CAMPAIGN, []);
    /* End of code to fix localStorage */
  } else {
    const compaignExistsInLocalStorage = utmCampaign?.some(
      (obj) => obj.campaign.utm_campaign === utmParams.utm_campaign
    );

    if (utmParams.utm_campaign !== '' && !compaignExistsInLocalStorage) {
      if (utmCampaign) {
        utmCampaign.push({ campaign: utmParams, timestamp });
        saveState(StorageKeys.UTM_CAMPAIGN, utmCampaign);
      } else {
        saveState(StorageKeys.UTM_CAMPAIGN, [{ campaign: utmParams, timestamp }]);
      }
    }
  }
};

export const getUTMDataForHubspot = () => {
  const utmCampaign: UTMCampaign[] = loadState(StorageKeys.UTM_CAMPAIGN);

  if (!utmCampaign) {
    return [];
  }

  return [
    { name: 'utm_campaign', value: utmCampaign[utmCampaign.length - 1]?.campaign?.utm_campaign || '' },
    { name: 'utm_source', value: utmCampaign[utmCampaign.length - 1]?.campaign?.utm_source || '' },
    { name: 'utm_medium', value: utmCampaign[utmCampaign.length - 1]?.campaign?.utm_medium || '' },
    { name: 'utm_content', value: utmCampaign[utmCampaign.length - 1]?.campaign?.utm_content || '' },
    { name: 'utm_term', value: utmCampaign[utmCampaign.length - 1]?.campaign?.utm_term || '' },
    { name: 'utm_timestamp', value: utmCampaign[utmCampaign.length - 1]?.timestamp.toString() || '' },
    { name: 'previous_touch_utm_campaign', value: utmCampaign[utmCampaign.length - 2]?.campaign?.utm_campaign || '' },
    { name: 'previous_touch_utm_source', value: utmCampaign[utmCampaign.length - 2]?.campaign?.utm_source || '' },
    { name: 'previous_touch_utm_medium', value: utmCampaign[utmCampaign.length - 2]?.campaign?.utm_medium || '' },
    { name: 'previous_touch_utm_content', value: utmCampaign[utmCampaign.length - 2]?.campaign?.utm_content || '' },
    { name: 'previous_touch_utm_term', value: utmCampaign[utmCampaign.length - 2]?.campaign?.utm_term || '' },
    { name: 'previous_touch_utm_timestamp', value: utmCampaign[utmCampaign.length - 2]?.timestamp.toString() || '' },
    {
      name: 'previous_x2_touch_utm_campaign',
      value: utmCampaign[utmCampaign.length - 3]?.campaign?.utm_campaign || '',
    },
    { name: 'previous_x2_touch_utm_source', value: utmCampaign[utmCampaign.length - 3]?.campaign?.utm_source || '' },
    { name: 'previous_x2_touch_utm_medium', value: utmCampaign[utmCampaign.length - 3]?.campaign?.utm_medium || '' },
    { name: 'previous_x2_touch_utm_content', value: utmCampaign[utmCampaign.length - 3]?.campaign?.utm_content || '' },
    { name: 'previous_x2_touch_utm_term', value: utmCampaign[utmCampaign.length - 3]?.campaign?.utm_term || '' },
    { name: 'previous_x2_touch_utm_timestamp', value: utmCampaign[utmCampaign.length - 3]?.timestamp.toString() || '' },
  ];
};
