import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';

/**
 * This is the main sanity client for most of the content since sanity was introduced to the repository.
 */
export const client = sanityClient({
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
  useCdn: process.env.DEVELOPMENT ? false : true, // `false` if you want to ensure fresh data
  // Sanity uses API versioning improve our APIs while also preventing existing code from breaking.
  // Be careful of changing this value without looking into possible breaking changes
  apiVersion: '2021-03-25',
  /**
   * Currently, our production dataset is public and development dataset is private.
   * For production environment we don't need this token, env-var is undefined. Client will still work.
   * For development environment this env-var needs to be defined, otherwise data can't be fetched.
   */
  token: process.env.SANITY_DATASET_ACCESS_TOKEN,
});

/**
 * If either new or old sanity access token is provided, then no-drafts sub-query will filter out drafts
 * in the query, where the sub-query is included.
 *
 * We export a single sub-query to be shared for queries with new and old client for convenience’s sake and to
 * avoid confusion.
 */
export const draftFilterSubQuery =
  !!process.env.SANITY_DATASET_ACCESS_TOKEN || !!process.env.SANITY_WEB_CONTENT_DATASET_ACCESS_TOKEN
    ? ' && !(_id in path("drafts.**"))'
    : '';

const urlBuilder = imageUrlBuilder(client);

export const sanityImageFor = (source: SanityImageSource) => urlBuilder.image(source);

export default client;
