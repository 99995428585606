import cx from 'classnames';
import Link from 'next/link';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { MarketingOptInFooter } from 'src/components/MarketingOptIn';
import { getCountryInfoByValue } from 'src/helpers/storeHelpers';
import { State as GlobalState } from 'src/redux/reducers/index';
import { eventActionEnum, eventCategoryEnum, pushGAEventToDataLayer } from 'src/services/googleTagManager';

import LinkList from './LinkList';
import { legalLinks, links } from './links';

type Props = ReturnType<typeof mapStateToProps> & { hideMarketingOptIn?: boolean };

const Footer: React.FunctionComponent<Props> = ({ country, countryData, hideMarketingOptIn = false }) => {
  const [isRegisteredTrademark, setIsRegisteredTrademark] = useState(false);

  const year = new Date().getFullYear();

  useEffect(() => {
    const currentCountry = getCountryInfoByValue(countryData, country);
    if (currentCountry && country === 'NO') {
      setIsRegisteredTrademark(true);
    } else {
      setIsRegisteredTrademark(false);
    }
  }, [country, countryData]);

  const trackClick = useCallback((label: string) => {
    pushGAEventToDataLayer(eventCategoryEnum.NAVIGATION, eventActionEnum.FOOTER_NAVIGATION, label);
  }, []);

  return (
    <>
      {!hideMarketingOptIn && <MarketingOptInFooter />}
      <footer className={cx('bg-grayscale-gray-800 text-white py-56 relative z-[1] md:py-[80px] md:px-0')}>
        <div className="md:grid grid-cols-13 max-w-[1160px] gap-x-32 mt-0 mb-64 mx-24 md:mx-40 md:mb-[72px] md:px-40 xl:mx-auto xl:mb-[120px] xl:max-w-[1360px]">
          <Link
            href="/"
            style={{ fontFamily: 'reMarkable Portrait' }}
            className={cx('inline-block	mb-24 no-underline text-[34px]')}
          >
            reMarkable
          </Link>
          <section className="col-span-3 mb-24 md:mb-0 md:col-start-6 lg:col-start-7 lg:col-span-2">
            <LinkList title="News & Info" links={links} trackClick={trackClick} />
          </section>
          <section className="col-span-3 md:col-start-9 lg:col-start-10">
            <LinkList title="Legal" links={legalLinks} trackClick={trackClick} />
          </section>
        </div>
        <div className="text-center">
          <small className="font-serif text-14">
            {`© ${year} reMarkable${isRegisteredTrademark ? '®' : '™'}.`}{' '}
            <span className="inline-block">All rights reserved.</span>
          </small>
        </div>
      </footer>
    </>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  country: state.countryDetector.country,
  countryData: state.staticQuery.countryData,
});

export default connect(mapStateToProps)(Footer);
