import { ImageUrlBuilderOptions } from '@sanity/image-url/lib/types/types';
import { FC, ImgHTMLAttributes } from 'react';
import { ImageElementStyles } from 'src/components/Image/Image';
import ResponsiveImagePictureContainer, { PictureElementStyling } from 'src/components/Image/ResponsiveImage';
import SanityImage, { SanityImageObject, SanityImageProps } from 'src/components/Image/SanityImage';
import { buildSanityImageSrcSet, MAX_DEFAULT_IMAGE_WIDTH } from 'src/components/Image/SanityImage/sanityImageHelpers';
import { sortedTwBpSizes } from 'src/helpers/tailwindBreakpointsHelpers';

export type SizeSet = {
  sanityImgObject?: SanityImageObject;
  sanityImgOptions?: ImageUrlBuilderOptions;
  bp: number;
};

export type SanityResponsiveImageProps = {
  imgDefault: SanityImageProps;
  imgSizeSet?: SizeSet[];
} & PictureElementStyling &
  ImageElementStyles;

const SanityResponsiveImage: FC<SanityResponsiveImageProps & ImgHTMLAttributes<HTMLImageElement>> = ({
  imgDefault,
  imgSizeSet,
  imgStyle = {},
  imgClassName = '',
  pictureStyle = {},
  pictureClassName = '',
  ...rest
}) => {
  const { sanityImgObject: defaultImgObject, sanityImgOptions } = imgDefault;
  const { bgColor } = defaultImgObject;

  // you either have to provide a sizeSet or you get images with width = bp, using sizes from tw config
  const imgSrcSet = imgSizeSet
    ? imgSizeSet.map(({ bp, sanityImgObject, sanityImgOptions = {} }) => ({
        bp,
        srcSet: buildSanityImageSrcSet(sanityImgObject ?? defaultImgObject, sanityImgOptions),
      }))
    : sortedTwBpSizes.map((size, i) => ({
        // generates bps up until second last element
        bp: size,
        srcSet: buildSanityImageSrcSet(defaultImgObject, {
          ...sanityImgOptions,
          // to avoid stretching images, we load a 475px image for breakpoint min-width: 375 (that will then be used up until bp 475px)
          width: sortedTwBpSizes.length === i + 1 ? MAX_DEFAULT_IMAGE_WIDTH : sortedTwBpSizes[i + 1],
        }),
      }));

  return (
    <ResponsiveImagePictureContainer
      imgSrcSet={imgSrcSet}
      pictureStyle={bgColor ? { backgroundColor: bgColor, ...pictureStyle } : pictureStyle}
      pictureClassName={pictureClassName}
    >
      <SanityImage {...imgDefault} {...rest} imgStyle={imgStyle} imgClassName={imgClassName} />
    </ResponsiveImagePictureContainer>
  );
};

export default SanityResponsiveImage;
