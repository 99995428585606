import { RefObject, useEffect, useRef, useState } from 'react';

type DOMElement = HTMLDivElement | HTMLInputElement;

export default function useOnScreen<T extends DOMElement>(): [RefObject<T>, boolean] {
  const ref = useRef<T>(null);
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return [ref, isIntersecting];
}
