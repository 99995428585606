import React, { useState } from 'react';
import { useCountryData } from 'src/hooks/useCountryData';

interface Props {
  small?: boolean;
  /** @default 'Newsletter Sign-up' */
  leadSource?: string;
}

const SFSignupForm: React.FC<Props> = ({ small, leadSource = 'Newsletter Sign-up' }) => {
  const [emailValue, setEmailValue] = useState('');
  const countryData = useCountryData();

  return (
    <div>
      <form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST">
        <input type="hidden" name="oid" value="00D7Q000002qCC5" />
        <input type="hidden" name="retURL" value="https://remarkable.com/" />
        <input id="00N7Q00000HQktY" name="00N7Q00000HQktY" type="hidden" value={countryData.country || ''} />
        <input id="recordType" name="recordType" type="hidden" value="0127Q000000ymcN" />
        <input id="lead_source" name="lead_source" type="hidden" value={leadSource} />
        <input id="emailOptOut" name="emailOptOut" type="hidden" value="false" />
        <input id="last_name" name="last_name" type="hidden" value={emailValue} />
        <div className="flex justify-center">
          <input
            className={`bg-light text-grayscale-gray-800 border-0 py-12 rounded-l-[24px] rounded-r-[0px] pl-20 pr-8 ${
              small ? 'px-12 text-14' : 'px-24'
            }`}
            id="email"
            name="email"
            type="email"
            onChange={(e) => setEmailValue(e.target.value)}
            required
            placeholder="Your email"
          />
          <input
            className={`appearance-none bg-brand-orange-400 border-0 font-demi text-white rounded-l-[0px] rounded-r-[24px] py-12 ${
              small ? 'px-12 text-14' : 'pl-20 pr-24 text-16'
            } `}
            type="submit"
            name="submit"
            value="Sign up"
          />
        </div>
      </form>
    </div>
  );
};

export default SFSignupForm;
